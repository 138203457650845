.Resizer {
	background: #000;
	opacity: 0.2;
	z-index: 1;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-moz-background-clip: padding;
	-webkit-background-clip: padding;
	background-clip: padding-box;
}

.Resizer:hover {
	-webkit-transition: all 2s ease;
	transition: all 2s ease;
}

.Resizer.horizontal {
	height: 11px;
	margin: -5px 0;
	border-top: 5px solid rgba(255, 255, 255, 0);
	border-bottom: 5px solid rgba(255, 255, 255, 0);
	cursor: row-resize;
	width: 100%;
}

.Resizer.horizontal:hover {
	border-top: 5px solid rgba(0, 0, 0, 0.5);
	border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
	width: 11px;
	margin: 0 -5px;
	border-left: 5px solid rgba(255, 255, 255, 0);
	border-right: 5px solid rgba(255, 255, 255, 0);
	cursor: col-resize;
}

.Resizer.vertical:hover {
	border-left: 5px solid rgba(0, 0, 0, 0.5);
	border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
	cursor: not-allowed;
}
.Resizer.disabled:hover {
	border-color: transparent;
}

.split-pane {
	background-color: #fff;
	overflow: hidden !important;
}

.pane-left,
.pane-right {
	transition: transform 0.5s ease-in-out;
	transform: translateX(0);
	display: flex;
	flex-direction: column;
	height: 100%;
}

@media only screen and (max-width: 450px) and (max-height: 950px) {
	.Pane {
		overflow-y: auto;
	}
}

@media only screen and (max-width: 600px) and (max-height: 750px) {
	.Pane {
		overflow-y: auto;
	}
}