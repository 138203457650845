// prettier-ignore
@font-family: "Source Sans Pro", "Verdana", "Helvetica", "Arial", "sans-serif";
// prettier-ignore
@highlight-blue: #5d7599;

/* HTML Tags */ 
html {
	height: 100%;
	scroll-behavior: smooth;
}

body {
	margin: 0;
	padding: 0;
	font-family: @font-family;
}

a {
	color: @highlight-blue;
	text-decoration: underline;

	&:visited,
	&:hover {
		color: @highlight-blue;
	}

	&:hover {
		text-decoration: underline;
	}
}

/* Scrollbar */
::-webkit-scrollbar {
	width: 8px;
	background-color: rgba(0, 0, 0, 0);
}
::-webkit-scrollbar-thumb:vertical {
	background: #c1c1c1;
	border-radius: 8px;
}
::-webkit-scrollbar-thumb:vertical:active {
	background: #7d7d7d;
	border-radius: 8px;
}
#content-scroll {
	&::-webkit-scrollbar-track {
		margin-top: 88px;
	}
}
